@import url(https://rsms.me/inter/inter-ui.css);

@tailwind preflight;

body {
  margin: 0;
  padding: 0;
}

#root {
  position: relative;
}

@tailwind components;

@tailwind utilities;
